import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";

import useHttp from "../../hooks/useHttp";
import useLoading from "../../hooks/useLoading";
import useSnackbar from "../../hooks/useSnackbar";

import { makeData } from "../Table/dataTest";
import { getDeviceName } from "../Table/misc";
import { ActionCell } from "../Table/controls";

import { Chip, Divider, Grid, Typography, colors } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { fuzzyFilter, enumFilter, getSortVal } from "../Table/misc";

import LocalTable from "../Table/LocalTable";
import { camelCaseToSpaceCase } from "../../utils/misc";
import { Circle, DataObject } from "@mui/icons-material";

import JSONDialog from "../Dialogs/JSONDialog";

const APILogs = () => {
  const theme = useTheme();

  const location = useLocation();
  const { get, post } = useHttp();
  const { loading, setLoading } = useLoading();
  const { openSnackbar } = useSnackbar();

  // const [data, setData] = useState(() => makeData(50, "transaction"));
  const [data, setData] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const [showJSONDialog, setShowJSONDialog] = useState(false);
  const [jsonLoading, setJSONLoading] = useState(false);
  const [json, setJSON] = useState({});

  const openJSONDialog = async (log) => {
    setJSON(log);
    setShowJSONDialog(true);

    // setLoading(true);

    // const res = await get(`/appLogs/${appLogId}/history`);
    // const isSuccessful = res.status < 400;
    // if (isSuccessful) {
    //   setJSON(res.data);
    //   setShowJSONDialog(true);
    // } else {
    //   openSnackbar({
    //     open: true,
    //     message: "An error occurred while loading the history",
    //     variant: "alert",
    //     alert: {
    //       color: "error",
    //     },
    //     close: false,
    //   });
    // }

    // setLoading(false);
  };

  const searchParams = location.search;

  const getLogs = async () => {
    const query = new URLSearchParams(searchParams);

    setLoading(true);
    const res = await get("/getAPILogs", {
      global: query.get("global"),
      filter: query.get("filter"),
      sort: query.get("sort"),
      page: query.get("page"),
      limit: query.get("limit"),
      timezone: moment.tz.guess(),
    });
    const isSuccessful = res.status < 400;

    if (isSuccessful) {
      setData(res.data.apiLogResult);
    }
    setLoading(false);
  };

  useEffect(() => {
    getLogs();
  }, [refetch, searchParams]);

  const columns = useMemo(() => {
    return [
      {
        header: "Actions",
        size: 50,
        enableHiding: false,
        sticky: "left",
        cell: ({ row }) => {
          const log = row.original;

          return ActionCell({
            row,
            theme,
            extrasArr: [
              {
                tooltipMsg: "Full History",
                handleClick: () => {
                  openJSONDialog(log);
                  // openJSONDialog(log._id);
                },
                icon: <DataObject />,
              },
            ],
            hideDelete: true,
            hideEdit: true,
          });
        },
      },
      {
        id: "code",
        header: "Code",
        size: 50,
        minSize: 50,
        accessorKey: "code",
        cell: ({ row }) => {
          const status = _.get(row, "original.meta.statusCode");

          if (!status) {
            return (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                -
              </div>
            );
          }

          const statusNum = Number(status);

          let color;
          if (_.isNaN(statusNum)) {
            color = colors.grey[300];
          } else if (statusNum < 300) {
            color = colors.green[500];
          } else if (statusNum < 400) {
            color = colors.yellow[500];
          } else {
            color = colors.red[500];
          }

          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <Chip
                label={status}
                style={{ backgroundColor: color }}
                size="small"
              >
                {status}
              </Chip>
            </div>
          );
        },
        // enableHiding: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        id: "method",
        header: "Method",
        size: 75,
        minSize: 75,
        accessorKey: "meta.method",
        // enableHiding: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        id: "type",
        header: "Type",
        size: 100,
        minSize: 100,
        accessorFn: (row) => {
          const incoming = _.get(row, "meta.incoming", false);
          const outgoing = _.get(row, "meta.outgoing", false);

          if (incoming) return "Incoming";
          else if (outgoing) return "Outgoing";
          else return "Unknown";

          // const message = _.get(row, "message", "Unknown").toLowerCase();

          // if (message.includes("outgoing")) {
          //   return "Outgoing";
          // } else if (message.includes("incoming")) {
          //   return "Incoming";
          // } else {
          //   return "Unknown";
          // }
        },
        filterFn: "includesString",
        // enableHiding: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        id: "path",
        header: "Path",
        size: 100,
        minSize: 100,
        accessorKey: "meta.url",
        // enableHiding: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        id: "timestamp",
        header: "Date",
        size: 175,
        minSize: 160,
        accessorFn: (row) =>
          moment(_.get(row, "timestamp")).format("YYYY-MM-DD HH:mm:ss"),
        filterFn: "includesString",
        // enableHiding: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        id: "id",
        header: "Request",
        size: 175,
        minSize: 150,
        accessorKey: "meta.requestId",
        filterFn: "includesString",
        // enableHiding: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
    ];
  }, []);

  return (
    <>
      <Grid
        className="w-full overflow-hidden rounded-lg"
        style={{
          margin: theme.spacing(2),
          padding: theme.spacing(3),
          backgroundColor: colors.grey[100],
        }}
      >
        <Typography variant="h4">API Logs</Typography>
        <Divider
          style={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        />
        <LocalTable
          name="logs-table"
          data={_.get(data, "logs", [])}
          columns={columns}
          manualPagination={_.omit(data, ["logs"])}
          hideSearch={true}
        />
      </Grid>
      <JSONDialog
        open={showJSONDialog}
        loading={jsonLoading}
        json={json}
        onClose={() => {
          setShowJSONDialog(false);
          setJSON({});
        }}
      />
    </>
  );
};

export default APILogs;
