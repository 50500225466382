import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";

import useHttp from "../../hooks/useHttp";
import useLoading from "../../hooks/useLoading";
import useSnackbar from "../../hooks/useSnackbar";

import { makeData } from "../Table/dataTest";
import {
  getDeviceName,
  fuzzyFilter,
  enumFilter,
  getSortVal,
} from "../Table/misc";
import { camelCaseToSpaceCase } from "../../utils/misc";

import { Divider, Grid, Typography, colors } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import LocalTable from "../Table/LocalTable";

const Transactions = () => {
  const theme = useTheme();

  const location = useLocation();
  const { get, post } = useHttp();
  const { loading, setLoading } = useLoading();
  const { openSnackbar } = useSnackbar();

  const [data, setData] = useState({});
  const [refetch, setRefetch] = useState(false);

  const searchParams = location.search;

  const getTransactions = async () => {
    setLoading(true);
    const query = new URLSearchParams(searchParams);

    const res = await get("/getTransactions", {
      global: query.get("global"),
      filter: query.get("filter"),
      sort: query.get("sort"),
      page: query.get("page"),
      limit: query.get("limit"),
      timezone: moment.tz.guess(),
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setData(res.data.transactionResult);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTransactions();
  }, [refetch, searchParams]);

  const columns = useMemo(() => {
    return [
      {
        id: "createdAt",
        header: "Created Date",
        size: 175,
        minSize: 160,
        accessorFn: (row) =>
          moment(_.get(row, "createdAt")).format("YYYY-MM-DD HH:mm:ss"),
        sortingFn: (rowA, rowB, colId) => {
          const dateA = new Date(_.get(rowA, "original.createdAt"));
          const dateB = new Date(_.get(rowB, "original.createdAt"));

          return getSortVal(dateA, dateB);
        },
        filterFn: "includesString",
        sortDescFirst: false,
      },
      {
        id: "apiKey",
        header: "API Key",
        size: 175,
        minSize: 150,
        accessorKey: "apiKey.name",
        enableHiding: false,
        filterFn: "includesString",
        sortDescFirst: false,
      },
      {
        id: "location",
        header: "Location",
        size: 175,
        minSize: 150,
        accessorKey: "location.name",
        enableHiding: false,
        filterFn: "includesString",
        sortDescFirst: false,
      },
      {
        id: "device",
        header: "Device",
        size: 175,
        minSize: 150,
        accessorFn: (row) => getDeviceName(_.get(row, "device", {})),
        enableHiding: false,
        filterFn: "includesString",
        sortDescFirst: false,
      },
      {
        header: "Amount",
        size: 125,
        minSize: 100,
        accessorKey: "amount",
        filterFn: "includesString",
        sortDescFirst: false,
        isNum: true, // needed for numerical cols on server paginated tables
      },
      {
        header: "Amount Paid",
        size: 125,
        minSize: 100,
        accessorKey: "amountPaid",
        filterFn: "includesString",
        sortDescFirst: false,
        isNum: true, // needed for numerical cols on server paginated tables
      },
      {
        id: "status",
        header: "Status",
        size: 125,
        minSize: 100,
        accessorFn: (row) => _.startCase(_.get(row, "status", "")),
        isEnum: true,
        filterFn: enumFilter,
        sortDescFirst: false,
      },
      {
        id: "type",
        header: "Type",
        size: 150,
        minSize: 125,
        accessorFn: (row) =>
          _.toUpper(camelCaseToSpaceCase(_.get(row, "type", ""))),
        isEnum: true,
        filterFn: enumFilter,
        sortDescFirst: false,
      },
      {
        header: "Bagtag",
        size: 150,
        minSize: 125,
        accessorKey: "bagtag",
        filterFn: "includesString",
        sortDescFirst: false,
      },
      {
        header: "Customer ID",
        size: 150,
        minSize: 125,
        accessorKey: "customerId",
        filterFn: "includesString",
        sortDescFirst: false,
      },
      {
        header: "Voucher Code",
        size: 150,
        minSize: 125,
        accessorKey: "voucherCode",
        filterFn: "includesString",
        sortDescFirst: false,
      },
      {
        header: "External Ref.\nID",
        size: 150,
        minSize: 125,
        accessorKey: "externalRefId",
        filterFn: "includesString",
        sortDescFirst: false,
      },
      {
        id: "reprints",
        header: "Is Reprint",
        size: 125,
        minSize: 100,
        accessorFn: (row) =>
          _.get(row, "reprints", []).length > 0 ? "Yes" : "No",
        sortDescFirst: false,
        isNum: true, // needed for numerical cols on server paginated tables
      },
    ];
  }, []);

  return (
    <Grid
      className="w-full overflow-hidden rounded-lg"
      style={{
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        backgroundColor: colors.grey[100],
      }}
    >
      <Typography variant="h4">Transactions</Typography>
      <Divider
        style={{
          marginBottom: theme.spacing(2),
          marginTop: theme.spacing(2),
        }}
      />
      <LocalTable
        name="transactions-table"
        data={_.get(data, "transactions", [])}
        columns={columns}
        manualPagination={_.omit(data, ["transactions"])}
      />
    </Grid>
  );
};

export default Transactions;
