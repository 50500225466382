import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import {
  TextField,
  Grid,
  Autocomplete,
  MenuItem,
  Stack,
  Switch,
  Typography,
  Collapse,
  Link,
} from "@mui/material";

import DefaultDialog from "../Dialogs/DefaultDialog";
import { TagInput } from "../Dialogs/controls";
import {
  WEBHOOK_FUNCTIONALITIES,
  IPV4_REGEX,
  EPAYMENT_WEBHOOK_TYPE,
  QR_WEBHOOK_TYPE,
} from "../../constants";

const CreateEditWebhookDialog = ({
  open,
  setOpen,
  webhook,
  handleSubmit,
  vendorOptions = [],
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isCreate = _.isNil(webhook);

  const schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    // vendor: Yup.string().required("Vendor is required"),
    active: Yup.boolean(),
    allowedFunctionalities: Yup.array().of(Yup.string()),
    urls: Yup.object({
      transactions: Yup.string("Invalid URL")
        .url("Invalid URL")
        .when(["$allowedFunctionalities"], ([allowedFunctionalities], schema) =>
          allowedFunctionalities.includes(EPAYMENT_WEBHOOK_TYPE)
            ? schema.required("URL is required")
            : schema
        ),

      // qrCodes: Yup.string("Invalid URL")
      //   .url("Invalid URL")
      //   .when(["$allowedFunctionalities"], ([allowedFunctionalities], schema) =>
      //     allowedFunctionalities.includes(QR_WEBHOOK_TYPE)
      //       ? schema.required("URL is required")
      //       : schema
      //   ),
    }),
  });

  const getInitialValues = (webhook) => {
    let initVals = {
      name: "",
      vendor: null,
      active: true,
      allowedFunctionalities: [],
      urls: {},
    };

    if (!_.isNil(webhook)) {
      initVals = _.mergeWith({}, initVals, webhook, (objVal, srcVal) => {
        if (_.isArray(srcVal)) {
          return srcVal; // needed for allowedDevices
        } else if (_.isObject(srcVal)) {
          return _.get(srcVal, "_id");
        }
      });
    }

    if (vendorOptions.length === 1)
      initVals.vendor = _.get(vendorOptions, ["0", "_id"]);

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(webhook),
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        const webhookToSubmit = _.cloneDeep(values);

        handleSubmit(webhookToSubmit);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <DefaultDialog
      open={open}
      title={`${isCreate ? "Create" : "Edit"} Webhook`}
      iconType={isCreate ? "add" : "edit"}
      handleClose={() => setOpen(false)}
      handleConfirm={handleSubmitFormik}
      confirmButtonName={isCreate ? "Create" : "Update"}
      closeButtonName="Cancel"
      disableConfirm={isSubmitting}
    >
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              {...getFieldProps("name")}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              label="Name"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
                shrink: !_.isEmpty(values.name),
              }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              required
              select
              fullWidth
              {...getFieldProps("vendor")}
              error={Boolean(touched.vendor && errors.vendor)}
              helperText={touched.vendor && errors.vendor}
              label="Vendor"
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
                shrink: !_.isEmpty(values.vendor),
              }}
              onChange={(e) => {
                const vendor = e.target.value;
                setFieldValue("vendor", vendor);
              }}
            >
              {_.isEmpty(vendorOptions) ? (
                <MenuItem disabled={true}>No vendors</MenuItem>
              ) : (
                vendorOptions.map((vendor) => (
                  <MenuItem value={vendor._id} key={vendor._id}>
                    {_.get(vendor, "name", "Unknown")}
                  </MenuItem>
                ))
              )}
            </TextField>
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              {...getFieldProps("allowedFunctionalities")}
              // helperText="Leave empty to authorize all functionalities for selected vendor"
              label="Functionalities"
              SelectProps={{ multiple: true }}
              InputLabelProps={{
                style: {
                  height: "1.2em",
                },
                shrink: !_.isEmpty(values.allowedFunctionalities),
              }}
            >
              {WEBHOOK_FUNCTIONALITIES.map((func) => (
                <MenuItem value={func} key={func}>
                  {func}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {values.allowedFunctionalities.includes(EPAYMENT_WEBHOOK_TYPE) && (
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                {...getFieldProps("urls.transactions")}
                error={Boolean(
                  // touched.urls?.transactions &&
                  errors.urls?.transactions
                )}
                helperText={
                  // touched.urls?.transactions &&
                  errors.urls?.transactions
                }
                label="Outgoing transactions endpoint"
                InputLabelProps={{
                  style: {
                    height: "1.2em",
                  },
                  shrink: !_.isEmpty(values.urls.transactions),
                }}
              />
            </Grid>
          )}
          {values.allowedFunctionalities.includes(QR_WEBHOOK_TYPE) && (
            <Grid item xs={12}>
              <Typography
                style={{
                  margin: theme.spacing(1),
                }}
                variant="subtitle2"
              >
                * QR Code sendout endpoints can be managed from the{" "}
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open("/dashboard/qrcodes", "_blank")}
                >
                  QR Lookup Table
                </Link>
              </Typography>
              {/* <TextField
                required
                fullWidth
                {...getFieldProps("urls.qrCodes")}
                error={Boolean(
                  // touched.urls?.qrCodes &&
                  errors.urls?.qrCodes
                )}
                helperText={
                  // touched.urls?.qrCodes &&
                  errors.urls?.qrCodes
                }
                label="Outgoing QR codes endpoint"
                InputLabelProps={{
                  style: {
                    height: "1.2em",
                  },
                  shrink: !_.isEmpty(values.urls.qrCodes),
                }}
              /> */}
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Switch
                checked={values.active}
                onChange={(event) =>
                  setFieldValue("active", event.target.checked)
                }
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography>Active</Typography>
            </Stack>
          </Grid>
        </Grid>
      </FormikProvider>
    </DefaultDialog>
  );
};

export default CreateEditWebhookDialog;
