import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import { formatDate } from "../../utils/misc";
import { getSortVal } from "../Table/misc";

import useHttp from "../../hooks/useHttp";
import useLoading from "../../hooks/useLoading";
import useSnackbar from "../../hooks/useSnackbar";

import { Button, Divider, Grid, Typography, colors } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import LocalTable from "../Table/LocalTable";

const FIELDS = {
  Error: "error",
  Refillable: "isRefillable",
  Rejected: "rejected",
  Count: "count",
  Volume: "volume",
  Barcode: "barcode",
  Weight: "sensorWeight",
  Pet: "sensorPet",
  Metal: "sensorMetal",
  Height: "sensorHeight",
  Diameter: "sensorDiameter",
  // Light: "isLight",
  // Deposited: "isDeposited",
};

const NUM_FIELDS = [
  "count",
  "volume",
  "sensorWeight",
  "sensorPet",
  "sensorMetal",
  "sensorHeight",
  "sensorDiameter",
];

const ENUM_FIELDS = ["isRefillable", "rejected"]; //, "isDeposited", "isLight"];

const Containers = () => {
  const theme = useTheme();

  const location = useLocation();
  const { get, post } = useHttp();
  const { loading, setLoading } = useLoading();
  const { openSnackbar } = useSnackbar();

  const [data, setData] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const searchParams = location.search;

  const getContainers = async () => {
    setLoading(true);
    const query = new URLSearchParams(searchParams);

    const res = await get("/getContainers", {
      global: query.get("global"),
      filter: query.get("filter"),
      sort: query.get("sort"),
      page: query.get("page"),
      limit: query.get("limit"),
      timezone: moment.tz.guess(),
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setData(res.data.containerResult);
    }
    setLoading(false);
  };

  useEffect(() => {
    getContainers();
  }, [refetch, searchParams]);

  const columns = useMemo(() => {
    const fields = [];
    fields.push(
      {
        id: "timestamp",
        header: "Date\nSubmitted",
        minSize: 150,
        accessorFn: (row) =>
          formatDate(_.get(row, "timestamp", ""), "YYYY-MM-DD HH:mm:ss"),
        sortingFn: (rowA, rowB, colId) => {
          const dateA = new Date(_.get(rowA, "original.timestamp"));
          const dateB = new Date(_.get(rowB, "original.timestamp"));

          return getSortVal(dateA, dateB);
        },
        sortDescFirst: false,
      },
      {
        id: "device",
        header: "Device Serial No.",
        minSize: 125,
        accessorKey: "device.0.serialNumber",
        cell: ({ row }) => {
          return _.get(row, "original.device.0.serialNumber", "-");
        },
        sortDescFirst: false,
      },
      {
        header: "External\nMaterial ID",
        minSize: 125,
        accessorKey: "materialId",
        accessorFn: (row) => _.get(row, "materialId", "-"),
        sortDescFirst: false,
      },
      {
        header: "Deposit\n(¢)",
        minSize: 100,
        accessorKey: "deposit",
        sortDescFirst: false,
        isNum: true,
      },
      {
        id: "depositGroup",
        header: "Deposit\nGroup",
        minSize: 125,
        accessorKey: "depositGroup.0.name",
        cell: ({ row }) => {
          return _.get(row, "original.depositGroup.0.name", "-");
        },
        sortDescFirst: false,
      }
    );

    for (let fieldLabel of Object.keys(FIELDS)) {
      const field = FIELDS[fieldLabel];

      fields.push({
        header: fieldLabel,
        minSize: 100,
        accessorKey: field,
        cell: ({ row }) => {
          return _.get(row, ["original", field], "-").toString();
        },
        sortDescFirst: false,
        isNum: NUM_FIELDS.includes(field),
        isEnum: ENUM_FIELDS.includes(field),
      });
    }

    return fields;
  }, []);

  return (
    <Grid
      className="w-full overflow-hidden rounded-lg"
      style={{
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        backgroundColor: colors.grey[100],
      }}
    >
      <Typography variant="h4">Containers</Typography>
      <Divider
        style={{
          marginBottom: theme.spacing(2),
          marginTop: theme.spacing(2),
        }}
      />
      <LocalTable
        name="containers-table"
        data={_.get(data, "containers", [])}
        columns={columns}
        manualPagination={_.omit(data, ["containers"])}
      />
    </Grid>
  );
};

export default Containers;
