import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import moment from "moment-timezone";

import useHttp from "../../hooks/useHttp";
import useLoading from "../../hooks/useLoading";
import useSnackbar from "../../hooks/useSnackbar";

import { makeData } from "../Table/dataTest";
import { getDeviceName } from "../Table/misc";

import {
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  colors,
  Popover,
  Tooltip,
} from "@mui/material";
import { Add, Info } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { arraySort, getSortVal, enumFilter } from "../Table/misc";
import { ActionCell } from "../Table/controls";

import LocalTable from "../Table/LocalTable";
import DeleteDialog from "../Dialogs/DeleteDialog";
import CreateEditWebhookDialog from "./CreateEditWebhookDialog";
import AlertDialog from "../Dialogs/AlertDialog";

import { QR_WEBHOOK_TYPE } from "../../constants";

const Webhooks = () => {
  const theme = useTheme();
  const { get, post } = useHttp();
  const { loading, setLoading } = useLoading();
  const { openSnackbar } = useSnackbar();

  const [editingWebhook, setEditingWebhook] = useState();
  const [showEditWebhookDialog, setShowEditWebhookDialog] = useState(false);
  const [webhookToDelete, setWebhookToDelete] = useState();
  const [showDeleteWebhookDialog, setShowDeleteWebhookDialog] = useState(false);

  const [showSecretDialog, setShowSecretDialog] = useState(false);
  const [secretForTempDisplay, setSecretForTempDisplay] = useState("");

  const [data, setData] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const [allVendors, setAllVendors] = useState([]);
  const [webhooks, setAllWebhooks] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(100);
  const [popoverContent, setPopoverContent] = useState();

  const getVendors = async () => {
    setLoading(true);
    const res = await get("/getVendors");
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setAllVendors(res.data.vendors);
    }
    setLoading(false);
  };

  const getWebhooks = async () => {
    setLoading(true);
    const res = await get("/getWebhooks");
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setAllWebhooks(res.data.webhooks);
    }
    setLoading(false);
  };

  const handleOpenPopover = (event) => {
    const target = event.currentTarget;
    setAnchorEl(target);
    setPopoverWidth(target.clientWidth);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCreateWebhook = async (newWebhook) => {
    setLoading(true);
    const res = await post("/createWebhook", { newWebhook });

    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditWebhookDialog(false);

      // Don't show webhook secret for QR webhook
      const hideSecret =
        newWebhook.allowedFunctionalities.length == 1 &&
        newWebhook.allowedFunctionalities.includes(QR_WEBHOOK_TYPE);
      if (!hideSecret) {
        setShowSecretDialog(true);
        setSecretForTempDisplay(res.data.secret);
      }

      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Successfully created webhook"
        : "Failed to create webhook",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleEditWebhook = async (newWebhook) => {
    setLoading(true);
    const res = await post("/updateWebhook", {
      newWebhook,
      _id: editingWebhook._id,
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditWebhookDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Succesfully updated webhook details"
        : "Failed to update webhook details",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleDeleteWebhook = async () => {
    setLoading(true);
    const res = await post("/deleteWebhook", {
      _id: webhookToDelete._id,
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowDeleteWebhookDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Succesfully deleted webhook"
        : "Failed to delete webhook",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  useEffect(() => {
    getVendors();
    getWebhooks();
  }, [refetch]);

  useEffect(() => {
    getVendors();
    getWebhooks();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        header: "Actions",
        size: 150,
        enableHiding: false,
        sticky: "left",
        cell: ({ row }) => {
          const webhook = row.original;
          return ActionCell({
            row,
            theme,
            handleEdit: () => {
              setEditingWebhook(webhook);
              setShowEditWebhookDialog(true);
            },
            handleDelete: () => {
              setWebhookToDelete(webhook);
              setShowDeleteWebhookDialog(true);
            },
          });
        },
      },
      {
        header: "Name",
        size: 200,
        minSize: 175,
        enableHiding: false,
        accessorKey: "name",
        filterFn: "includesString",
      },
      // {
      //   id: "vendor",
      //   header: "Vendor",
      //   size: 200,
      //   minSize: 175,
      //   accessorKey: "vendor.name",
      //   filterFn: "includesString",
      // },
      {
        id: "active",
        header: "Status",
        size: 175,
        minSize: 150,
        accessorFn: (row) =>
          _.get(row, "active", false) ? "Active" : "Deactivated",
        isEnum: true,
        filterFn: enumFilter,
      },
      // {
      //   id: "lastAccessed",
      //   header: "Last Accessed",
      //   size: 175,
      //   minSize: 150,
      //   accessorFn: (row) => {
      //     const lastAccessed = moment(_.get(row, "lastAccessed"));
      //     return lastAccessed.isValid()
      //       ? lastAccessed.format("YYYY-MM-DD HH:mm:ss")
      //       : "N/A";
      //   },
      //   sortingFn: (rowA, rowB, colId) => {
      //     const dateA = new Date(_.get(rowA, "original.lastAccessed"));
      //     const dateB = new Date(_.get(rowB, "original.lastAccessed"));

      //     return getSortVal(dateA, dateB);
      //   },
      //   filterFn: "includesString",
      // },
      // {
      //   id: "allowedDevices",
      //   header: "Devices",
      //   headerAdditions: (
      //     <Tooltip
      //       title="No devices selected means all devices authorized for respective vendor"
      //       placement="top"
      //     >
      //       <Info sx={{ fontSize: 20, ml: 0.5, mt: 0.25 }} color="primary" />
      //     </Tooltip>
      //   ),
      //   size: 200,
      //   minSize: 175,
      //   accessorFn: (row) =>
      //     _.get(row, "allowedDevices", [])
      //       .map((device) => getDeviceName(device))
      //       .join(","),
      //   cell: ({ row }) => {
      //     // will probably need the devices' ID for links so can't just use accessor values
      //     const devices = _.get(row, "original.allowedDevices", []);
      //     const deviceCount = devices.length;

      //     const popoverDevices = _.sortBy(devices, [
      //       "type",
      //       "serialNumber",
      //     ]).map((device) => (
      //       <Typography key={device._id} variant="body2" sx={{ p: 1 }}>
      //         {getDeviceName(device)}
      //       </Typography>
      //     ));

      //     return (
      //       <Button
      //         onClick={(e) => {
      //           handleOpenPopover(e);
      //           setPopoverContent(popoverDevices);
      //         }}
      //         className="w-full text-center"
      //         variant="outlined"
      //         disabled={!deviceCount}
      //       >
      //         {deviceCount > 0
      //           ? `${deviceCount} device${deviceCount > 1 ? "s" : ""}`
      //           : "All devices"}
      //       </Button>
      //     );
      //   },
      //   sortingFn: (rowA, rowB) => arraySort(rowA, rowB, "allowedDevices"),
      //   filterFn: "includesString",
      // },
      // {
      //   id: "allowedIPs",
      //   header: "IPs",
      //   headerAdditions: (
      //     <Tooltip
      //       title="No IPs selected means all IPs authorized for respective vendor"
      //       placement="top"
      //     >
      //       <Info sx={{ fontSize: 20, ml: 0.5, mt: 0.25 }} color="primary" />
      //     </Tooltip>
      //   ),
      //   size: 200,
      //   minSize: 175,
      //   accessorFn: (row) => _.get(row, "allowedIPs", []).join(","),
      //   cell: ({ row }) => {
      //     const ips = _.get(row, "original.allowedIPs", []);
      //     const ipCount = ips.length;

      //     const popoverIPs = ips.sort().map((ip) => (
      //       <Typography key={ip} variant="body2" sx={{ p: 1 }}>
      //         {ip}
      //       </Typography>
      //     ));

      //     return (
      //       <Button
      //         onClick={(e) => {
      //           handleOpenPopover(e);
      //           setPopoverContent(popoverIPs);
      //         }}
      //         className="w-full text-center"
      //         variant="outlined"
      //         disabled={!ipCount}
      //       >
      //         {ipCount > 0
      //           ? `${ipCount} IP${ipCount > 1 ? "s" : ""}`
      //           : "All IPs"}
      //       </Button>
      //     );
      //   },
      //   sortingFn: (rowA, rowB) => arraySort(rowA, rowB, "allowedIPs"),
      //   filterFn: "includesString",
      // },
      {
        id: "allowedFunctionalities",
        header: "Functionalities",
        // headerAdditions: (
        //   <Tooltip
        //     title="No functionalities selected means all functionalities authorized for respective vendor"
        //     placement="top"
        //   >
        //     <Info sx={{ fontSize: 20, ml: 0.5, mt: 0.25 }} color="primary" />
        //   </Tooltip>
        // ),
        size: 225,
        minSize: 200,
        accessorFn: (row) => _.get(row, "allowedFunctionalities", []).join(","),
        cell: ({ row }) => {
          const functionalities = _.get(
            row,
            "original.allowedFunctionalities",
            []
          );

          return _.isEmpty(functionalities) ? (
            <Typography variant="body2">None</Typography>
          ) : (
            <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
              {functionalities.map((func) => (
                <Chip label={func} key={func} />
              ))}
            </Stack>
          );
        },
        sortingFn: (rowA, rowB) =>
          arraySort(rowA, rowB, "allowedFunctionalities"),
        filterFn: "includesString",
      },
      // {
      //   id: "createdBy",
      //   header: "Created By",
      //   size: 200,
      //   minSize: 175,
      //   accessorKey: "createdBy.name.full",
      //   filterFn: "includesString",
      // },
      // {
      //   id: "updatedBy",
      //   header: "Updated By",
      //   size: 200,
      //   minSize: 175,
      //   accessorKey: "updatedBy.name.full",
      //   filterFn: "includesString",
      // },
    ];
  }, []);

  const popoverOpen = Boolean(anchorEl);

  return (
    <>
      <Grid
        className="w-full overflow-hidden rounded-lg"
        style={{
          margin: theme.spacing(2),
          padding: theme.spacing(3),
          backgroundColor: colors.grey[100],
        }}
      >
        <Typography variant="h4">Webhooks</Typography>
        <Divider
          style={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        />
        <LocalTable
          name="webhooks-table"
          data={webhooks}
          columns={columns}
          initColVisibility={{ createdBy: false, updatedBy: false }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setEditingWebhook();
              setShowEditWebhookDialog(true);
            }}
            disabled={loading}
            sx={{ width: "max-content" }}
          >
            <Add style={{ marginRight: theme.spacing(1) }} />
            Create webhook
          </Button>
        </LocalTable>
      </Grid>
      <DeleteDialog
        open={showDeleteWebhookDialog}
        handleClose={() => setShowDeleteWebhookDialog(false)}
        handleDelete={handleDeleteWebhook}
        text={`Are you sure you want to delete webhook, ${_.get(
          webhookToDelete,
          "name",
          ""
        )}?`}
      />
      <AlertDialog
        open={showSecretDialog}
        handleClose={() => {
          setShowSecretDialog(false);
          setSecretForTempDisplay("");
        }}
        title="Your webhook secret"
        maxWidth="sm"
      >
        <Stack spacing={2}>
          <Typography>Here is your webhook client secret:</Typography>
          <Typography
            variant="body2"
            component="code"
            className="p-2 border-solid border-[1px]"
          >
            {secretForTempDisplay}
          </Typography>
          <Typography className="font-medium">
            Use this secret to authenticate payloads sent from MDDS.
          </Typography>
          <Typography className="text-red-400 font-medium">
            WARNING: Once you close this window, you will not be able to see
            this secret again. Make sure you note it down and keep it in a safe
            place.
          </Typography>
        </Stack>
      </AlertDialog>
      <CreateEditWebhookDialog
        open={showEditWebhookDialog}
        webhook={editingWebhook}
        setOpen={setShowEditWebhookDialog}
        handleSubmit={editingWebhook ? handleEditWebhook : handleCreateWebhook}
        vendorOptions={allVendors}
      />
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack
          style={{
            width: `${popoverWidth}px`,
            maxHeight: "200px",
            overflow: "auto",
          }}
        >
          {popoverContent}
        </Stack>
      </Popover>
    </>
  );
};

export default Webhooks;
