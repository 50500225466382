import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import moment from "moment-timezone";

import useHttp from "../../hooks/useHttp";
import useLoading from "../../hooks/useLoading";
import useSnackbar from "../../hooks/useSnackbar";

import { makeData } from "../Table/dataTest";
import { getDeviceName } from "../Table/misc";

import {
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  colors,
  Popover,
  Tooltip,
} from "@mui/material";
import { Add, Info } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { arraySort, getSortVal, enumFilter } from "../Table/misc";
import { ActionCell } from "../Table/controls";

import LocalTable from "../Table/LocalTable";
import DeleteDialog from "../Dialogs/DeleteDialog";
import CreateEditQRCodeDialog from "./CreateEditQRCodeDialog";
import AlertDialog from "../Dialogs/AlertDialog";

const QRCodes = () => {
  const theme = useTheme();
  const { get, post } = useHttp();
  const { loading, setLoading } = useLoading();
  const { openSnackbar } = useSnackbar();

  const [editingQRCode, setEditingQRCode] = useState();
  const [showEditQRCodeDialog, setShowEditQRCodeDialog] = useState(false);
  const [qrCodeToDelete, setQRCodeToDelete] = useState();
  const [showDeleteQRCodeDialog, setShowDeleteQRCodeDialog] = useState(false);

  const [showSecretDialog, setShowSecretDialog] = useState(false);
  const [secretForTempDisplay, setSecretForTempDisplay] = useState("");

  const [data, setData] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const [qrCodes, setAllQRCodes] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(100);
  const [popoverContent, setPopoverContent] = useState();

  const getQRCodes = async () => {
    setLoading(true);
    const res = await get("/getQRCodes");
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setAllQRCodes(res.data.qrCodes);
    }
    setLoading(false);
  };

  const handleOpenPopover = (event) => {
    const target = event.currentTarget;
    setAnchorEl(target);
    setPopoverWidth(target.clientWidth);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCreateQRCode = async (newQRCode) => {
    setLoading(true);
    const res = await post("/createQRCode", { newQRCode });

    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditQRCodeDialog(false);
      setShowSecretDialog(true);
      setSecretForTempDisplay(res.data.secret);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Successfully created lookup"
        : "Failed to create lookup",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleEditQRCode = async (newQRCode) => {
    setLoading(true);
    const res = await post("/updateQRCode", {
      newQRCode,
      _id: editingQRCode._id,
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowEditQRCodeDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Succesfully updated lookup"
        : "Failed to update lookup",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  const handleDeleteQRCode = async () => {
    setLoading(true);
    const res = await post("/deleteQRCode", {
      _id: qrCodeToDelete._id,
    });
    const isSuccessful = res.status < 400;
    if (isSuccessful) {
      setShowDeleteQRCodeDialog(false);
      setRefetch((prev) => !prev);
    }

    openSnackbar({
      open: true,
      message: isSuccessful
        ? "Succesfully deleted lookup"
        : "Failed to delete lookup",
      variant: "alert",
      alert: {
        color: isSuccessful ? "success" : "error",
      },
      close: false,
    });
    setLoading(false);
  };

  useEffect(() => {
    getQRCodes();
  }, [refetch]);

  const columns = useMemo(() => {
    return [
      {
        header: "Actions",
        size: 175,
        enableHiding: false,
        sticky: "left",
        cell: ({ row }) => {
          const qrCode = row.original;
          return ActionCell({
            row,
            theme,
            handleEdit: () => {
              setEditingQRCode(qrCode);
              setShowEditQRCodeDialog(true);
            },
            handleDelete: () => {
              setQRCodeToDelete(qrCode);
              setShowDeleteQRCodeDialog(true);
            },
          });
        },
      },
      {
        header: "Prefix",
        size: 200,
        minSize: 175,
        enableHiding: false,
        accessorKey: "prefix",
        filterFn: "includesString",
      },
      {
        id: "url",
        header: "URL",
        size: 200,
        minSize: 175,
        accessorKey: "url",
        filterFn: "includesString",
      },
    ];
  }, []);

  const popoverOpen = Boolean(anchorEl);

  return (
    <>
      <Grid
        className="w-full overflow-hidden rounded-lg"
        style={{
          margin: theme.spacing(2),
          padding: theme.spacing(3),
          backgroundColor: colors.grey[100],
        }}
      >
        <Typography variant="h4">QR Lookup Table</Typography>
        <Divider
          style={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        />
        <LocalTable
          name="qrcodes-table"
          data={qrCodes}
          columns={columns}
          initColVisibility={{ createdBy: false, updatedBy: false }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setEditingQRCode();
              setShowEditQRCodeDialog(true);
            }}
            disabled={loading}
          >
            <Add style={{ marginRight: theme.spacing(1) }} />
            Create Lookup
          </Button>
        </LocalTable>
      </Grid>
      <DeleteDialog
        open={showDeleteQRCodeDialog}
        handleClose={() => setShowDeleteQRCodeDialog(false)}
        handleDelete={handleDeleteQRCode}
        text={`Are you sure you want to delete lookup, ${_.get(
          qrCodeToDelete,
          "prefix",
          ""
        )}?`}
      />
      <CreateEditQRCodeDialog
        open={showEditQRCodeDialog}
        qrCode={editingQRCode}
        setOpen={setShowEditQRCodeDialog}
        handleSubmit={editingQRCode ? handleEditQRCode : handleCreateQRCode}
      />
      <AlertDialog
        open={showSecretDialog}
        handleClose={() => {
          setShowSecretDialog(false);
          setSecretForTempDisplay("");
        }}
        title="Your webhook URL secret"
        maxWidth="sm"
      >
        <Stack spacing={2}>
          <Typography>Here is your webhook URL client secret:</Typography>
          <Typography
            variant="body2"
            component="code"
            className="p-2 border-solid border-[1px]"
          >
            {secretForTempDisplay}
          </Typography>
          <Typography className="font-medium">
            Use this secret to authenticate payloads sent from MDDS.
          </Typography>
          <Typography className="text-red-400 font-medium">
            WARNING: Once you close this window, you will not be able to see
            this secret again. Make sure you note it down and keep it in a safe
            place.
          </Typography>
        </Stack>
      </AlertDialog>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack
          style={{
            width: `${popoverWidth}px`,
            maxHeight: "200px",
            overflow: "auto",
          }}
        >
          {popoverContent}
        </Stack>
      </Popover>
    </>
  );
};

export default QRCodes;
